<template>
  <div />
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

  export default {
    name: 'Forwarder',

    components: {},

    data: () => ({}),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        const key = String(window.location.href).replace(/.*\/(\w+)\/?$/, '$1')

        const requestBody = {
          ml_hash: key,
        }

        axios.post('/v1/auth/phone-wa/confirm', requestBody).then((res) => {
          if (res.data.status === 200) {
            // localStorage.setItem('userMenus', (res.data.data.cms_access).toString())
            localStorage.setItem('token', res.data.data.member_login_token)
            localStorage.setItem('userinfo', JSON.stringify(res.data.data))
            this.$store.commit('login/SET_LOGIN_INFO', res.data.data)
            this.$router.push({ name: 'Dashboard' })
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
            this.$router.push({ name: 'Login' })
          })
      },
    },
  }
</script>

<style scoped>
.form-container {
  height: 100vh;
}

.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #808080;
}

.custom-card {
  width: auto;
  height: 60px;
  background: #fafafa;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}
</style>
